import React from 'react';

const TitleSection = ({ title = 'Title' }) => {
	return (
		<React.Fragment>
			<p className="pl-2 pb-3 gradient-text-color font-semibold">{title}</p>
		</React.Fragment>
	);
};

export default TitleSection;
