import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

import { Autoplay, FreeMode, Navigation } from 'swiper/modules';
import CardLottery from './components/CardLottery';
import TitleSection from '../../atom/Title/TitleSection';
import { MotionComponent } from '../../atom/MotionComponent';
import BrandConfigStore from '@/context/store/brand-config';
import useFetchApi from '@/hooks/useFetchApi.hook';
import { GameApiService } from '../../../../services/api/games/game.api.service';
import useAuth from '../../../features/auth/useAuth';

const GameLotteryResultComponent = ({
	slideMode,
	showTitle = false,
	navigation = false,
	centeredSlides = false
}) => {
	const { t } = useTranslation();
	const { brandConfig } = BrandConfigStore((state) => ({ brandConfig: state.config }));
	const { loginToken, sessionToken, isLoggedIn } = useAuth();

	const { data, error } = useFetchApi(GameApiService.GameNoAuth, {
		shouldFetch: true,
		initialConfig: {
			headers: {
				Authorization: loginToken || sessionToken
			},
			data: {
				brandId: brandConfig.brandId,
				systemType: 'getListLottery'
			}
		},
		dependencies: [loginToken, sessionToken, isLoggedIn],
		customCondition: () => loginToken || sessionToken
	});

	let lotteryResultList = null;

	const SwiperComponent = ({ children }) => {
		return (
			<>
				<Swiper
					slidesPerView={'auto'}
					spaceBetween={12}
					loop={true}
					freeMode={true}
					centeredSlides={centeredSlides}
					navigation={navigation}
					speed={5500}
					autoplay={{
						delay: 1,
						disableOnInteraction: false // Continue autoplay after interaction
					}}
					pagination={{
						clickable: true
					}}
					modules={[Autoplay, FreeMode, Navigation]}
					className="mySwiper">
					{children}
				</Swiper>
			</>
		);
	};

	lotteryResultList = (
		<>
			<SwiperComponent>
				{Array.from({ length: 10 }).map((_, index) => (
					<SwiperSlide key={index} className="!w-28">
						<CardLottery cardType="loading" />
					</SwiperSlide>
				))}
			</SwiperComponent>
		</>
	);

	if (data) {
		lotteryResultList = (
			<>
				<SwiperComponent>
					{(Array.isArray(data?.data) ? [...data.data, ...data.data] : []).map((item, index) => (
						<SwiperSlide key={`${item.id}-${index}`} className="!w-28">
							<CardLottery lottery={item} />
						</SwiperSlide>
					))}
				</SwiperComponent>
			</>
		);
	}

	if (error) {
		lotteryResultList = <>Something error</>;
	}

	return (
		<>
			<MotionComponent slideMode={slideMode}>
				<div className="relative w-full flex flex-col mb-section">
					{showTitle && <TitleSection title={t('label.winningNumbers')} />}
					<div className="flex h-40 w-full">{lotteryResultList}</div>
				</div>
			</MotionComponent>
		</>
	);
};

export default GameLotteryResultComponent;
