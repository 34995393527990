const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
dayjs.extend(utc);
dayjs.extend(timezone);

const getDate = ({
	targetDate,
	millisecondsToAdd = 0,
	secondsToAdd = 0,
	minutesToAdd = 0,
	hoursToAdd = 0,
	daysToAdd = 0,
	weeksToAdd = 0,
	monthsToAdd = 0,
	yearsToAdd = 0,
	timeZone = 'UTC',
	format,
	toDate = false,
	toDateString = false,
	toEpoch = false
} = {}) => {
	let day = dayjs(targetDate).tz(timeZone);

	if (millisecondsToAdd) day = day.add(millisecondsToAdd, 'millisecond');
	if (secondsToAdd) day = day.add(secondsToAdd, 'second');
	if (minutesToAdd) day = day.add(minutesToAdd, 'minute');
	if (hoursToAdd) day = day.add(hoursToAdd, 'hour');
	if (daysToAdd) day = day.add(daysToAdd, 'day');
	if (weeksToAdd) day = day.add(weeksToAdd, 'week');
	if (monthsToAdd) day = day.add(monthsToAdd, 'month');
	if (yearsToAdd) day = day.add(yearsToAdd, 'year');

	if (toDate) {
		return day.toDate();
	}

	if (toDateString) {
		return day.toDate().toISOString();
	}

	if (toEpoch) {
		return day.unix();
	}

	return day.format(format);
};

const getDateNoTZ = ({
	targetDate,
	millisecondsToAdd = 0,
	secondsToAdd = 0,
	minutesToAdd = 0,
	hoursToAdd = 0,
	daysToAdd = 0,
	weeksToAdd = 0,
	monthsToAdd = 0,
	yearsToAdd = 0,
	// timeZone = 'UTC',
	format,
	toDate = false,
	toDateString = false,
	toEpoch = false
} = {}) => {
	let day = dayjs(targetDate);

	if (millisecondsToAdd) day = day.add(millisecondsToAdd, 'millisecond');
	if (secondsToAdd) day = day.add(secondsToAdd, 'second');
	if (minutesToAdd) day = day.add(minutesToAdd, 'minute');
	if (hoursToAdd) day = day.add(hoursToAdd, 'hour');
	if (daysToAdd) day = day.add(daysToAdd, 'day');
	if (weeksToAdd) day = day.add(weeksToAdd, 'week');
	if (monthsToAdd) day = day.add(monthsToAdd, 'month');
	if (yearsToAdd) day = day.add(yearsToAdd, 'year');

	if (toDate) {
		return day.toDate();
	}

	if (toDateString) {
		return day.toDate().toISOString();
	}

	if (toEpoch) {
		return day.unix();
	}

	return day.format(format);
};

const formatDateTime = (datetime) => {
	return dayjs(datetime).format('DD/MMM/YYYY HH:mm:ss Z');
};

const formatDate = (datetime) => {
	return dayjs(datetime).format('DD/MMM/YYYY');
};

const formatTime = (datetime) => {
	return dayjs(datetime).format('HH:mm:ss');
};

const formatTimeZone = (datetime) => {
	return dayjs(datetime).format('Z');
};

const formatDateUtils = {
	getDate,
	getDateNoTZ,
	formatDateTime,
	formatDate,
	formatTime,
	formatTimeZone
};

export default formatDateUtils;
