import { apiInstance } from '../apiInstance';

export class GameApiService {
	static async GameNoAuth(config = {}) {
		// Destructure the data and headers from the config object
		const { data, headers } = config;

		// Use apiInstance directly to make the API call
		const response = await apiInstance({
			method: 'post',
			url: '/v1/gameNoAuth',
			data: data,
			headers: headers
		});

		return response.data;
	}

	static async SearchGame(config = {}) {
		// Destructure the data and headers from the config object
		const { data, headers } = config;

		// Use apiInstance directly to make the API call
		const response = await apiInstance({
			method: 'post',
			url: '/v1/searchGame',
			data: data,
			headers: headers
		});

		return response.data;
	}

	static async GetGameUrl(config = {}) {
		// Destructure the data and headers from the config object
		const { data, headers } = config;

		// Use apiInstance directly to make the API call
		const response = await apiInstance({
			method: 'post',
			url: '/v1/getGameUrl',
			data: data,
			headers: headers
		});

		return response.data;
	}

	static async GetDemoGameUrl(config = {}) {
		// Destructure the data and headers from the config object
		const { data } = config;

		// Use apiInstance directly to make the API call
		const response = await apiInstance({
			method: 'post',
			url: '/v1/demo-get-game-url',
			data: data
		});

		return response.data;
	}

	static async GetGameProvider(config = {}) {
		// Destructure the data and headers from the config object
		const { data } = config;

		// Use apiInstance directly to make the API call
		const response = await apiInstance({
			method: 'post',
			url: '/v1/demo-get-game-url',
			data: data
		});

		return response.data;
	}

	static async GetLastPlayedGame(config = {}) {
		// Destructure the data and headers from the config object
		const { headers } = config;

		// Use apiInstance directly to make the API call
		const response = await apiInstance({
			method: 'post',
			url: '/v1/lastPlayGames',
			headers: headers
		});

		return response.data;
	}

	static async GetGameList2(config = {}) {
		// Destructure the data and headers from the config object
		const { headers, data } = config;

		// Use apiInstance directly to make the API call
		const response = await apiInstance({
			method: 'post',
			url: '/v1/getgamelist2',
			headers: headers,
			data: data
		});

		return response.data;
	}
}
