import React from 'react';
import Link from 'next/link';
import formatDateUtils from '../../../../../../utils/formatDate';
import appRoutes from '../../../../../core/routes';

const CardLottery = ({ lottery, cardType }) => {
	switch (cardType) {
		case 'loading':
			return (
				<React.Fragment>
					<div className="relative rounded-lg gradient-lottery-card h-full">
						{/* Top Bar */}
						<div className="flex items-center justify-center w-full h-8 rounded-t-lg text-lottery-title text-xs font-bold text-black">
							Lottery
						</div>

						{/* Main Content Area */}
						<div className="w-full h-32 rounded-b-lg gradient-border-lottery-card">
							<div className="p-2 space-y-2">
								{/* Centered Text */}
								<div className="h-2 leading-none bg-container-color-primary animate-pulse rounded-lg"></div>
								{/* Centered Circle */}
								<div className="flex w-full justify-center">
									<div className="w-16 h-16 aspect-square animate-pulse bg-container-color-primary rounded-lg"></div>
								</div>

								{/* Absolutely Positioned Text at the Bottom */}
								<div className="absolute bottom-2 left-0 right-0 px-2">
									<div className="h-4 leading-none text-center bg-container-color-primary animate-pulse rounded-lg"></div>
								</div>
							</div>
						</div>
					</div>
				</React.Fragment>
			);

		default:
			return (
				<React.Fragment>
					<Link
						href={{
							pathname: appRoutes.WINNING_NUMBERS,
							query: {
								room_id: lottery?.gpGameId,
								room_name: lottery?.title
							}
						}}>
						<a>
							<div className="relative rounded-lg gradient-lottery-card h-full">
								{/* Top Bar */}
								<div className="flex items-center justify-center w-full h-8 rounded-t-lg text-lottery-title text-xs font-bold">
									<p>{lottery?.title}</p>
								</div>

								{/* Main Content Area */}
								<div className="w-full h-32 rounded-b-lg gradient-border-lottery-card">
									<div className="p-2 space-y-2">
										<div className="text-xxs leading-none text-center text-container-color-primary">
											{formatDateUtils.getDateNoTZ({
												format: 'DD MMMM YYYY',
												targetDate: lottery?.date
											})}
										</div>
										<div className="flex w-full justify-center">
											<div className="flex items-center w-16 h-16 aspect-square">
												<img src={lottery?.image} alt="" className="" />
											</div>
										</div>
										<div className="flex w-full justify-center">
											<div className="text-xl leading-none text-container-color-primary">
												{lottery?.number[0]}
											</div>
										</div>
									</div>
								</div>
							</div>
						</a>
					</Link>
				</React.Fragment>
			);
	}
};

export default CardLottery;
